
import { defineComponent } from 'vue'
import Main from '@/templates/Main.vue'
import HistoryOfBalanceSection from '@/components/sections/dashboard/HistoryOfBalanceSection.vue'

export default defineComponent({
  name: 'HistoryOfBalance',
  components: {
    Main,
    HistoryOfBalanceSection
  }
})
